@layer components {
  .frameableImage {
    color: var(--color-gray-500);
    line-height: 0;
    vertical-align: middle;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    display: var(--frameable-image-display);
    stroke: var(--color-indigo-500);
  }
}
